var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "설비 목록",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.grid.data,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                hideBottom: true,
                editable: _vm.editable,
                rowKey: "key",
              },
              on: { rowClick: _vm.rowClick, linkClick: _vm.rowClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: {
                          label: "기기정비 이력기록서",
                          icon: "assignment",
                        },
                        on: { btnClicked: _vm.btnRecord },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.isCheck === "1"
        ? _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              attrs: { topClass: "topcolor-orange" },
            },
            [
              _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    title: "설비별 점검항목",
                    columns: _vm.grid2.columns,
                    gridHeight: _vm.grid2.height,
                    data: _vm.record.equipmentResultList,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    hideBottom: true,
                    noDataLabel: "설비에 대한 점검항목이 없습니다.",
                    rowKey: "checkItemSeq",
                    cardClass: "topcolor-orange",
                  },
                },
                [_c("template", { slot: "table-button" })],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isCheck === "2"
        ? _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: {
                    title: "설비 정비결과",
                    topClass: "topcolor-orange",
                    bgClass: "",
                  },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _vm.editable && _vm.record.maintenanceCompleteFlagName
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "정비후 점검결과",
                                value: _vm.record.maintenanceCompleteFlagName,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.record.inspResultAfterMemName
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "정비후 점검결과",
                                value: _vm.record.inspResultAfterMemName,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.record.maintenanceTimeT
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "정비시간",
                                value: _vm.record.maintenanceTimeT,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.record.stopDrivingTimeT
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "운전정지시간",
                                value: _vm.record.stopDrivingTimeT,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.record.maintenanceQualifications
                      ? _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "정비자 자격사항",
                                value: _vm.record.maintenanceQualifications,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.record.maintenanceCause
                      ? _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "정비원인",
                                value: _vm.record.maintenanceCause,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.record.maintenanceContent
                      ? _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "정비내용",
                                value: _vm.record.maintenanceContent,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.record.maintenanceResultSummary
                      ? _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "정비결과요약",
                                value: _vm.record.maintenanceResultSummary,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.record.reviewOpinionOfMananger
                      ? _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "관리자의 검토의견",
                                value: _vm.record.reviewOpinionOfMananger,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }